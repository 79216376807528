import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { ART_AND_DESIGN } from '~/src/constants/CategoryTab';

import { policyAPIClient } from '../../../api/v1/PolicyAPI';
import { LGSHomePolicyStringSlideItem } from '../../../types/home-policy';
import { URIParser } from '../../../utils/uri-parser';
import { UseHomePolicyArguments } from './useHomePolicy.types';

export const useHomePolicy = (args?: UseHomePolicyArguments) => {
  const policy = useQuery({
    queryKey: ['policy', 'home'],
    queryFn: () => {
      return policyAPIClient.getHome();
    },
    onSuccess: ({ data }) => {
      args?.onLoadedHomePolicy && args.onLoadedHomePolicy(data);
    },
  });

  const stringSlideItems = useMemo<LGSHomePolicyStringSlideItem[]>(() => {
    const sections = policy.data?.data?.sections ?? [];
    const stringSlide = sections.find((section) => section.type === 'stringSlide'); // FIXME : 참조하는 곳 모두 찾아서 별도 상수로 분리 필요(기존 코드 리팩터링)

    if (!stringSlide || !stringSlide.items) return [];

    // 중간에 있는 Art & Design 카테고리를 맨 앞으로 재정렬한다
    const { items : categoryItems } = stringSlide;
    const targetItemIndex = categoryItems.findIndex((item) => item.title === ART_AND_DESIGN);
    const targetItem = categoryItems[targetItemIndex];
    const prevItems = categoryItems.slice(0, targetItemIndex);
    const afterItems = categoryItems.slice(targetItemIndex + 1);

    const sortedCategoryItems = [targetItem, ...prevItems, ...afterItems];

    return sortedCategoryItems.map((item) => {
      const id = URIParser.getCategoryId(item.value);
      return { ...item, id } as LGSHomePolicyStringSlideItem;
    });

  }, [policy.data]);

  return {
    policy,
    stringSlideItems,
  };
};
